/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/functions";
@import '~bootstrap/scss/mixins';

// @import 'functions-override';

$primary: #6bb793;
// $secondary: #6bb793;
$yiq-contrasted-threshold: 200 !default;
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

$theme-colors: (
    // "primary": hsl(307, 100%, 23%)
);


// $primary: var(--primary);

// $theme-colors: (
//   primary: var(--primary)
// );
@import '~bootstrap/scss/variables';
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

@import "variables.scss";


.toolbar-icon, .toolbar-icon::after {
    content: $logo-url !important;
    max-width: $logo-size;
    max-height: $logo-size;
    display: inline-block;
    align-content: center;
  }

  .navbar-brand, strong
  {
    display: inline-block;
    align-content: center;
  }

#toolbar-icon img {
    background-image: $logo-url;
    max-width: $logo-size;
    max-height: $logo-size;
    display: inline-block;
}